import jquery from "jquery";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: "https://b7812e3b3e1545ab9d77f1dbb3cfb19c@o448037.ingest.sentry.io/5442148",
  environment: "production", // TODO: Set at build time
  integrations: [new BrowserTracing()],
  release: "5.37.0",
  tracesSampleRate: 0.5,
});

window.jQuery = jquery;
window.$ = jquery;
